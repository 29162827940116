
import { useContext } from 'react';

import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { FableMenu } from '../../Components';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AuthContext from '../../contexts/authContext';
import DialogContext from '../../contexts/dialogContext';
import { _deleteFable, _report } from '../../apis';

import axios from 'axios';
import fileDownload from 'js-file-download';
import toWav from 'audiobuffer-to-wav';

export default function MoreMenu({pos, show, item, setShow, refreshCb}) {
    const authCtx = useContext(AuthContext);
    const dialogCtx = useContext(DialogContext);

    const handleClose = (e) => {
        setShow(false);
        e.preventDefault();
    };

    const handleShare = () => {
        dialogCtx.showShare(item.title, `${window.location.origin}/#/fable/${item.id}`);
        setShow(false);
    }

    const handleDownload = () => {
        const ext = item.audioFile.split(".").pop();

        // axios.get(item.audioFile, {
        //     responseType: 'blob',
        //   })
        //   .then((res) => {
        //     fileDownload(res.data, item.title + "." + ext)
        //   })
          
        setShow(false);

        axios.get(item.audioFile, {
            responseType: 'blob',
        }).then((res) => {
            const blob = res.data;
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const reader = new FileReader();
        
            reader.onload = function(event) {
                const arrayBuffer = event.target.result;
        
                audioContext.decodeAudioData(arrayBuffer, function(audioBuffer) {
                    let detuneValueInCents = 100; // adjust this value to change the pitch
                    if (item.voice === undefined || item.voice === null)
                        item.voice = 100;
                    if (item.voice >= 100)
                        detuneValueInCents = 100 + (item.voice - 100) * 1100;
                    else
                        detuneValueInCents = -1200 + 13 * item.voice;

                    const offlineContext = new OfflineAudioContext(audioBuffer.numberOfChannels, audioBuffer.length, audioBuffer.sampleRate);

                    const offlineSource = offlineContext.createBufferSource();
                    offlineSource.buffer = audioBuffer;

                    // Create a ScriptProcessorNode to modify the pitch
                    const scriptNode = offlineContext.createScriptProcessor(4096, 1, 1);
                    scriptNode.onaudioprocess = function(event) {
                        const inputData = event.inputBuffer.getChannelData(0);
                        const outputData = event.outputBuffer.getChannelData(0);

                        for (let i = 0; i < inputData.length; i++) {
                            const ratio = Math.pow(2, detuneValueInCents / 1200); // convert cents to a multiplier
                            const currentIndex = Math.floor(i * ratio);
                            outputData[i] = inputData[currentIndex] || 0;
                        }
                    };

                    offlineSource.connect(scriptNode);
                    scriptNode.connect(offlineContext.destination);
                    offlineSource.start(0);

                    offlineContext.startRendering().then(function(renderedBuffer) {
                        let wav = toWav(renderedBuffer);
                        let blob = new window.Blob([new DataView(wav)], {
                            type: 'audio/wav'
                        });

                        // Use 'file-saver' package function to save the blob as WAV file
                        fileDownload(blob, item.title + "." + ext)
                    });
                });
            };
        
            reader.readAsArrayBuffer(blob);
        });
    }

    const handleReport = () => {
        _report(authCtx.getUserId(), item.id)
        .then((res) => {
            if (res.success === 1) {
                dialogCtx.showToast(res.message);
                refreshCb();
            }
            else
                dialogCtx.showError(res.message);
        })
        .catch((e) => {
            dialogCtx.showError(e.message);
        })
        
        setShow(false);
    }

    const handleDelete = () => {
        setShow(false);
        _deleteFable(
            authCtx.getUserId(),
            item.id,
        )
        .then((res) => {
            if (res.success === 1) {
                dialogCtx.showToast(res.message);
                authCtx.goToProfile();
            }
            else
                dialogCtx.showError(res.message);
        })
        .catch((e) => {
            dialogCtx.showError(e.message);
        })
    }

    return <FableMenu
        anchorReference="anchorPosition"
        anchorPosition={pos}
        open={show}
        onClose={handleClose}
    >
        <MenuItem onClick={handleShare}>
            <ListItemIcon>
                <ShareIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Share</ListItemText>
        </MenuItem>
        {/*authCtx.getUserId() === item.authorId && (
            <MenuItem>
                <ListItemIcon>
                    <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
            </MenuItem>
        )*/}
        {authCtx.getUserId() !== item.authorId && (
            <MenuItem onClick={handleReport}>
                <ListItemIcon>
                    <InfoOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{item.isReportedByMe === false ? "Report" : "Unreport"}</ListItemText>
            </MenuItem>
        )}
        <MenuItem onClick={handleDownload}>
            <ListItemIcon>
                <DownloadIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Download</ListItemText>
        </MenuItem>
        {authCtx.getUserId() === item.authorId && (
            <MenuItem onClick={handleDelete}>
                <ListItemIcon>
                    <DeleteOutlineIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
            </MenuItem>
        )}
    </FableMenu>
}